import React from 'react';
import { SmoothScrollSection } from './smooth-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const AboutSection = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(({ children }, aboutMeRef) => {
  return (
    <SmoothScrollSection sectionId="about" className="l-about" ref={aboutMeRef}>
      <div className="flex__column flex__align__center flex__justify__center max__width min__section__height max__section__height">
        <div className="section-title__container">
          <h2 className="section-title text__bold">About Me</h2>
        </div>
        <div className="section__container">
          <div className="about__img">
            <img src="./img/about-me-3.png" alt="Khert Geverola" />
          </div>
          <div className="about__caption">
            <h2 className="about__subtitle">I'am Khert</h2>
            <div className="about__checklist flex flex__column">
              <div className="about__checklist__item flex">
                <div className="checklist__icon__wrapper flex flex__align__center flex__justify__center">
                  <FontAwesomeIcon
                    className="about__icon"
                    icon={faCheckCircle}
                  />
                </div>
                <div className="text__wrapper flex flex__align__center">
                  <p className="about__text">
                    A Software Developer in Cebu, City Philippines.
                  </p>
                </div>
              </div>
              <div className="about__checklist__item flex">
                <div className="checklist__icon__wrapper flex flex__align__center flex__justify__center">
                  <FontAwesomeIcon
                    className="about__icon"
                    icon={faCheckCircle}
                  />
                </div>
                <div className="text__wrapper flex flex__align__center">
                  <p className="about__text">
                    Loves to develop softwares and websites.
                  </p>
                </div>
              </div>
              <div className="about__checklist__item flex">
                <div className="checklist__icon__wrapper flex flex__align__center flex__justify__center">
                  <FontAwesomeIcon
                    className="about__icon"
                    icon={faCheckCircle}
                  />
                </div>
                <div className="text__wrapper flex flex__align__center">
                  <p className="about__text">
                    Loves to explore new technologies.
                  </p>
                </div>
              </div>
              <div className="about__checklist__item flex">
                <div className="checklist__icon__wrapper flex flex__align__center flex__justify__center">
                  <FontAwesomeIcon
                    className="about__icon"
                    icon={faCheckCircle}
                  />
                </div>
                <div className="text__wrapper flex flex__align__center">
                  <p className="about__text">
                    Always ready to accept new challenges.
                  </p>
                </div>
              </div>
              <div className="about__checklist__item flex">
                <div className="checklist__icon__wrapper flex flex__align__center flex__justify__center">
                  <FontAwesomeIcon
                    className="about__icon"
                    icon={faCheckCircle}
                  />
                </div>
                <div className="text__wrapper flex flex__align__center">
                  <p className="about__text">
                    Always on the lookout for exciting projects to work on.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmoothScrollSection>
  );
});
