import React from 'react';
import { SmoothScrollSection } from './smooth-scroll';

export const SkillsSection = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(({ children }, skillsRef) => {
  return (
    <SmoothScrollSection
      sectionId="skills"
      className="l-skills"
      ref={skillsRef}
    >
      <div className="flex__column flex__align__center flex__justify__center max__width min__section__height max__section__height">
        <div className="section-title__container">
          <h2 className="section-title text__bold">Skills</h2>
        </div>

        <div className="section__container">
          <h2 className="skills__subtitle">Professional Skills</h2>
          <div className="skills__list">
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-csharp.png" alt="Csharp" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-java.png" alt="Java" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-php.svg" alt="Php" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-nodejs.png" alt="NodeJs" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-aspnet.png" alt="Asp.Net" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-angular.svg" alt="Angular" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-vue.png" alt="Vue" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-react.svg" alt="React" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-html.png" alt="Html" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-css.png" alt="Css" />
              </div>
            </div>
            <div className="skills__item">
              <div className="skills__item__image__container">
                <span className="skills__image__helper"></span>
                <img src="./img/skills-js.png" alt="Js" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmoothScrollSection>
  );
});
