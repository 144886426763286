import React from 'react';
import { SmoothScrollSection } from './smooth-scroll/SmoothScrollSection';

export const WorksSection = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(({ children }, worksRef) => {
  return (
    <SmoothScrollSection sectionId="works" className="l-works" ref={worksRef}>
      <div className="flex__column flex__align__center flex__justify__center max__width min__section__height">
        <div className="section-title__container">
          <h2 className="section-title text__bold">Works</h2>
        </div>
        <div className="section__container">
          <div className="works__list">
            <div className="works__item">
              <div className="works__item__image__container">
                <img
                  src="./img/works-carpool-login.png"
                  alt="Carpool Login Page"
                />
              </div>
            </div>
            <div className="works__item">
              <div className="works__item__image__container">
                <img
                  src="./img/works-carpool-dashboard.png"
                  alt="Carpool Dashboard"
                />
              </div>
            </div>
            <div className="works__item">
              <div className="works__item__image__container">
                <img
                  src="./img/works-famchat-login.png"
                  alt="Famchat Login Page"
                />
              </div>
            </div>
            <div className="works__item">
              <div className="works__item__image__container">
                <img
                  src="./img/works-viodyne-home.png"
                  alt="Viodyne Home Page"
                />
              </div>
            </div>
            <div className="works__item">
              <div className="works__item__image__container">
                <img
                  src="./img/works-viodyne-products.png"
                  alt="Viodyne Products Page"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmoothScrollSection>
  );
});
