import React from 'react';

type TypeProps = React.HTMLProps<HTMLElement>;

interface IProps {
  sectionId: string;
  className: string;
}

export const SmoothScrollSection = React.forwardRef<
  HTMLElement,
  IProps & TypeProps
>(({ sectionId, className, children }, sectionRef) => {
  return (
    <section id={sectionId} ref={sectionRef} className={className}>
      {children}
    </section>
  );
});
