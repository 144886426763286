import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faFacebookSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';

export const AppFooter: React.FC = () => {
  const getYear = (): string =>
    new Date().getFullYear() > 2020 ? ` - ${new Date().getFullYear()}` : '';

  return (
    <footer className="l-footer">
      <div className="footer_container">
        <p className="footer__title">KG</p>
        <div className="footer__social">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ph.linkedin.com/in/khert-geverola-44b336161"
            className="footer__icon"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/khertconnor"
            className="footer__icon"
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/khertconner/?hl=en"
            className="footer__icon"
          >
            <FontAwesomeIcon icon={faInstagramSquare} />
          </a>
        </div>
        <p className="footer__copyrights">
          &#169; 2020{getYear()} All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};
