import React from 'react';
import { SmoothScrollSection } from './smooth-scroll/SmoothScrollSection';

export const ContactSection = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(({ children }, contactRef) => {
  return (
    <SmoothScrollSection
      sectionId="contact"
      className="l-contact"
      ref={contactRef}
    >
      <div className="flex__column flex__align__center flex__justify__center max__width min__section__height max__section__height">
        <div className="section-title__container">
          <h2 className="section-title text__bold">Contact</h2>
        </div>
        <div className="section__container">
          <form action="" className="contact__form">
            <input type="text" placeholder="Name" className="contact__input" />
            <input
              type="email"
              placeholder="Email"
              className="contact__input"
            />
            <textarea
              name=""
              id=""
              rows={10}
              className="contact__input"
            ></textarea>

            <input
              type="button"
              value="Submit"
              className="contact__submit__button cursor__pointer btn rounded btn-primary"
            />
          </form>
        </div>
      </div>
    </SmoothScrollSection>
  );
});
