import React, { Fragment, useState, useEffect, useRef } from 'react';
import { AppHeader } from './components/AppHeader';
import { AppFooter } from './components/AppFooter';
import { HomeSection } from './components/HomeSection';
import { AboutSection } from './components/AboutSection';
import { SkillsSection } from './components/SkillsSection';
import { WorksSection } from './components/WorksSection';
import { ContactSection } from './components/ContactSection';

export interface SectionItem {
  id: string;
  name: string;
  ref: React.RefObject<HTMLElement>;
}

function App() {
  const homeRef = useRef<HTMLElement>(null);
  const aboutMeRef = useRef<HTMLElement>(null);
  const skillsRef = useRef<HTMLElement>(null);
  const worksRef = useRef<HTMLElement>(null);
  const contactRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);

  const [showFixedHeader, setShowFixedHeader] = useState<boolean>(false);
  const [prevScroll, setPrevScroll] = useState<number>(
    window.scrollY || document.documentElement.scrollTop
  );
  const [curScrollDirection, setCurScrollDirection] = useState<number>(0);
  const [prevScrollDirection, setPrevScrollDirection] = useState<number>(
    curScrollDirection
  );
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [showNavMenu, setShowNavMenu] = useState<boolean>(false);
  const sectionList = [
    {
      id: 'home',
      name: 'Home',
      ref: homeRef,
    },
    {
      id: 'about',
      name: 'About Me',
      ref: aboutMeRef,
    },
    {
      id: 'skills',
      name: 'Skills',
      ref: skillsRef,
    },
    {
      id: 'works',
      name: 'Works',
      ref: worksRef,
    },
    {
      id: 'contact',
      name: 'Contact',
      ref: contactRef,
    },
  ];

  const updateHeaderHeight = (number: number) => {
    setHeaderHeight(number);
  };

  const toggleNavMenu = () => {
    if (
      window.scrollY <= headerHeight ||
      document.documentElement.scrollTop <= headerHeight
    ) {
      setShowFixedHeader(!showFixedHeader);
    }
    setShowNavMenu(!showNavMenu);
  };

  const detectScrollDirection = (curScroll: number) => {
    // Scroll up   =  1
    // Neutral     =  0
    // Scroll down = -1
    if (curScroll > prevScroll) {
      setCurScrollDirection(-1);
    } else {
      setCurScrollDirection(1);
    }
    setPrevScroll(curScroll);
  };

  const manualScrollingDetection = (curScroll: number) => {
    if (curScroll >= headerHeight) {
      if (curScrollDirection === 1) {
        setShowFixedHeader(true);
      } else if (curScrollDirection === -1) {
        setShowFixedHeader(false);
        setShowNavMenu(false);
      }
    } else {
      setShowFixedHeader(false);
    }
  };

  const scrollEventCallback = () => {
    var curScroll = window.scrollY || document.documentElement.scrollTop;

    detectScrollDirection(curScroll);

    manualScrollingDetection(curScroll);
    setPrevScrollDirection(curScrollDirection);
    setPrevScroll(curScroll);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollEventCallback);
    return () => {
      window.removeEventListener('scroll', scrollEventCallback);
    };
  });

  return (
    <Fragment>
      <AppHeader
        setHeaderHeight={updateHeaderHeight}
        showNavMenu={showNavMenu}
        toggleNavMenu={toggleNavMenu}
        sectionList={sectionList}
        showFixedHeader={showFixedHeader}
        headerHeight={headerHeight}
      />
      <main className="l-main">
        <HomeSection ref={homeRef} contactRef={contactRef}/>
        <AboutSection ref={aboutMeRef} />
        <SkillsSection ref={skillsRef} />
        <WorksSection ref={worksRef} />
        <ContactSection ref={contactRef}/>
      </main>
      <AppFooter />

      {/* For re presentational testing only */}
      <div className="test">
        <table>
          <tbody>
            <tr>
              <td>Live Scroll</td>
              <td>{window.scrollY || document.documentElement.scrollTop}</td>
            </tr>
            <tr>
              <td>Current Scroll Direction</td>
              <td>{curScrollDirection}</td>
            </tr>
            <tr>
              <td>Prev Scroll Direction</td>
              <td>{prevScrollDirection}</td>
            </tr>
            <tr>
              <td>Show Header?</td>
              <td>{curScrollDirection === 1 ? 'true' : 'false'}</td>
            </tr>
            <tr>
              <td>Show Fixed Header?</td>
              <td>{showFixedHeader ? 'true' : 'false'}</td>
            </tr>
            <tr>
              <td>Show Nav Menu?</td>
              <td>{showNavMenu ? 'true' : 'false'}</td>
            </tr>
            <tr>
              <td>Header height</td>
              <td>{headerHeight}</td>
            </tr>
            <tr>
              <td><button onClick={() => {console.log(headerRef.current?.clientHeight)}}>CLICK</button></td>
              <td>test</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default App;
