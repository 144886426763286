import React from 'react';
import { SmoothScrollSection } from './smooth-scroll';

interface IProps {
  contactRef: React.RefObject<HTMLElement>;
}

export const HomeSection = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & IProps
>(({ contactRef }, homeRef) => {
  return (
    <SmoothScrollSection className="l-home" sectionId="home" ref={homeRef}>
      <div className="home__overlay">
        <div className="home__container flex flex__align__center max__width">
          <div className="home__heading">
            <h1 className="home__title text__bold">
              <span className="home__sub__title text__uppercase">
                Hi There, I'm
              </span>
              <span className="home__name">Khert Geverola</span>
              <span className="home__job__description">Software Engineer</span>
            </h1>
            <button
              className="home__call__to__action__button btn"
              onClick={() =>
                window.scrollTo({
                  top: contactRef.current?.offsetTop,
                  behavior: 'smooth',
                })
              }
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </SmoothScrollSection>
  );
});
