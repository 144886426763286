import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { SectionItem } from '../App';

interface IProps {
  showFixedHeader: boolean;
  setHeaderHeight: (number: number) => void;
  headerHeight: number;
  showNavMenu: boolean;
  toggleNavMenu: () => void;
  sectionList: SectionItem[];
}

export const AppHeader: React.FC<IProps> = ({
  setHeaderHeight,
  showNavMenu,
  toggleNavMenu,
  sectionList,
  showFixedHeader,
  headerHeight,
}) => {
  const headerRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef, setHeaderHeight]);

  const scrollToSection = (
    isScrollDirectionUp: boolean,
    offSetHeight?: number
  ) => {
    if (isScrollDirectionUp && offSetHeight && headerRef.current) {
      offSetHeight = offSetHeight - headerRef.current.clientHeight;
    }
    window.scrollTo({ top: offSetHeight, behavior: 'smooth' });
  };

  return (
    <header
      className={classNames('l-header', { fixed: showFixedHeader })}
      ref={headerRef}
    >
      <nav className="header__nav">
        <div className="flex max__width">
          <div className="logo__wrapper flex flex__align__center flex__justify__center">
            <div
              className="nav__logo text__fw__500 no__select cursor__pointer"
              onClick={() => scrollToSection(true, 0)}
            >
              KG
            </div>
          </div>
          <div
            className={classNames('app__overlay', { show: showNavMenu })}
            onClick={toggleNavMenu}
          ></div>

          <div
            className={classNames('nav__menu flex__column', {
              show: showNavMenu,
            })}
          >
            <ul className="nav__list flex__column">
              {sectionList.map((section) => (
                <li className="nav__item max__width" key={section.id}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(
                        !(
                          (window.scrollY ||
                            document.documentElement.scrollTop) <
                          (section.ref.current?.offsetTop || 0)
                        ),
                        section.ref.current?.offsetTop
                      );
                      toggleNavMenu();
                    }}
                    className="nav__link no__select cursor__pointer"
                  >
                    {section.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div
            className="nav__toggle ml-auto flex flex__align__center flex__justify__center cursor__pointer"
            onClick={toggleNavMenu}
          >
            <FontAwesomeIcon icon={showNavMenu ? faTimes : faBars} />
          </div>
        </div>
      </nav>
    </header>
  );
};
